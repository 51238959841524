import { render, staticRenderFns } from "./ComponentFaqConecteseComoQuiser.vue?vue&type=template&id=c2cd9894&"
import script from "./ComponentFaqConecteseComoQuiser.vue?vue&type=script&lang=ts&"
export * from "./ComponentFaqConecteseComoQuiser.vue?vue&type=script&lang=ts&"
import style0 from "./ComponentFaqConecteseComoQuiser.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VImg,VRow,VSlideGroup,VSlideItem,VSpacer})
