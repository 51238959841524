



































































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { ServicesProductAPI } from "@/plugins/api/servicesProductAPI"
import ComponentButtonAddToCart from "@/components/buttons/ComponentButtonAddToCart.vue"
@Component({
  components: {
    ComponentButtonAddToCart,
  },
})
export default class ComponentFaqConecteseComoQuiser extends mixins(
  ServicesProductAPI,
) {
  get computedSvaProdutoDestaqueIcons (): string[] {
    const ALL_SVA_ICONS:string[] = []
    Object.keys(this.allProductDataBase).map(product => {
      if (this.allProductDataBase[product].spotlight) {
        Object.keys(this.allProductDataBase[product].vas).map((vas) => {
          if (/redes-sociais|mensagens/.test(vas)) {
            this.allProductDataBase[product].vas[vas].items.map(item => {
              ALL_SVA_ICONS.push(item)
            })
          }
        })
      }
    })
    return ALL_SVA_ICONS
  }
}
