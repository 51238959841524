















































import { Component, Prop } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { ServicesProductAPI } from "@/plugins/api/servicesProductAPI"
@Component
export default class ComponentButtonAddToCart extends mixins(
  ServicesProductAPI,
) {
  @Prop({ default: "primary" }) readonly color?: string
}
