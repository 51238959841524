





























































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { MixinFormat } from "@/mixins/format/MixinFormat"
import { ServicesProductAPI } from "@/plugins/api/servicesProductAPI"
@Component
export default class ComponentFaqNovoTimControle extends mixins(
  MixinFormat,
  ServicesProductAPI,
) {
  cardFaqSlideGroup = [
    {
      title: "Entre em contato",
      image: "1%20-%20Adquira%20o%20Novo%20Tim%20Controle.png",
      texto: "Fale conosco por meio de nosso telefone de Contato",
    },
    {
      title: "Siga nas redes sociais",
      image: "2%20-%20%20Baixe%20o%20App%20do%20C6%20Bank%20com%20o%20n%C3%BAmero%20TIM%20Controle%2C%20abra%20sua%20conta%20e%20ganhe%20b%C3%B4nus.png",
      texto: "Conheça nossas redes sociais",
    },
    {
      title: "Esteja informado",
      image: "3%20-%20Continue%20ganhando%20b%C3%B4nus%20todo%20m%C3%AAs%20pagando%20sua%20fatura%20TIM%20Controle%20pelo%20C6%20Bank.png",
      texto: "Leia nossos artigos",
    },
  ]
}
