






























































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
@Component
export default class ComponentFaqDuvidaParceriaC6Bank extends mixins() {
  cardFaqExpansionPanel = [
    {
      titulo: "Quem somos?",
      texto: "Somos advogados",
    },
    {
      titulo: "Onde pode nos encontrar?",
      texto: "Você pode conhecer nosso escritório e nos seguir nas redes sociais",
    },
  ]
}
