














































































import { Vue, Component } from "vue-property-decorator"
import ComponentFaqNovoTimControle from "@/components/faq/ComponentFaqNovoTimControle.vue"
import ComponentFaqDuvidaParceriaC6Bank from "@/components/faq/ComponentFaqDuvidaParceriaC6Bank.vue"
import ComponentFaqConecteseComoQuiser from "@/components/faq/ComponentFaqConecteseComoQuiser.vue"
import ComponentFaqPrincipaisDuvidas from "@/components/faq/ComponentFaqPrincipaisDuvidas.vue"
@Component({
  components: {
    ComponentFaqNovoTimControle,
    ComponentFaqDuvidaParceriaC6Bank,
    ComponentFaqConecteseComoQuiser,
    ComponentFaqPrincipaisDuvidas,
  },
})
export default class ViewFaq extends Vue {}
