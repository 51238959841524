
































































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
@Component
export default class ComponentFaqPrincipaisDuvidas extends mixins() {
  globalInformations = require("@/data/global/GlobalInformations.json")
  cardFaqExpansionPanel = [
    {
      titulo: "O que muda com a LGPD",
      texto: "Você agora fica ciente de cada dado solicitado a você",
    },
    {
      titulo: "O que faço em caso de vazamento de dados?",
      texto: `Você pode entrar em contato por telefone, ligando para <a href='tel:${String(this.globalInformations.telefone.atendimento || "").replace(/\W/g, "")}'rel='noopener'>${this.globalInformations.telefone.atendimento}</a> ou em nosso E-mail.`,
    },
  ]
}
