
































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
@Component
export default class ViewHero extends mixins() {}
